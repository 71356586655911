import {LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {WzLoaderComponent} from './shared/components/loader/wz-loader.component';
import {CommonModule, registerLocaleData} from '@angular/common';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxsModule} from '@ngxs/store';
import {NGX_STATES} from './app-ngxs.state';
import {provideEnvironmentNgxMask} from 'ngx-mask';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';

import es_ES from '@angular/common/locales/es';
import es_MX from '@angular/common/locales/es-MX';
import {AppRoutingModule} from './app-routing.module';
import {NZ_CONFIG} from 'ng-zorro-antd/core/config';
import {es_ES as nzEs, NZ_I18N} from 'ng-zorro-antd/i18n';
import {NG_ZORRO_CONFIG} from './app-ng-zorro.config';
registerLocaleData(es_MX, 'es-Mx');
registerLocaleData(es_ES, 'es');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AppRoutingModule,
    WzLoaderComponent,
    BrowserAnimationsModule,
    NgxsModule.forRoot(NGX_STATES),
  ],
  providers: [
    { provide: NZ_I18N, useValue: nzEs },
    { provide: NZ_CONFIG, useValue: NG_ZORRO_CONFIG },
    { provide: LOCALE_ID, useValue: 'es-MX' },
    provideEnvironmentNgxMask({}),
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
