import {CityState} from './shared/states/location/city.state';
import {StateLocationState} from './shared/states/location/state.state';
import {CountryState} from './shared/states/location/country.state';
import {UserOrganizationState} from './shared/states/user-organization.state';
import {EventState} from './shared/states/event.state';
import {LoaderState} from './shared/components/loader/state/loader.state';
import {OrganizationState} from './shared/states/organization.state';
import {UserState} from './shared/states/user.state';

export const NGX_STATES: any[] = [
  CountryState,
  StateLocationState,
  CityState,
  UserOrganizationState,
  EventState,
  LoaderState,
  OrganizationState,
  UserState,
]
